import React from 'react';
import styled from 'styled-components';
import TemplatePage1 from '@components/TemplatePage/TemplatePage';


const IndexPage = () => {


  return (
    <TemplatePage1
      title={"Objetivo 1: Consolidarse como la red latinoamericana QuEST LAC"}
      showtitle={false}
      description={"Objetivo 1: Consolidarse como la red latinoamericana QuEST LAC"}
      bg={"var(--tema4)"}
      content={(
        <Wrapper>
          
        </Wrapper>
      )}
    />
  )
};

export default IndexPage

const Wrapper = styled.div`

`;